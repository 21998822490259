<template>
  <div class="prod">
    <div class="banner-wrap">
      <div class="banner">
        <div class="banner-box">
          <div class="banner-text">
            <p class="title">四大核心产品</p>
            <p class="title">两大应用领域</p>
          </div>
          <div class="banner-img" id="banner-img"></div>
        </div>
      </div>
    </div>
    <section>
      <div class="matrix">
        <div class="matrix-wrap">
          <p class="title">产品矩阵</p>
          <div class="matrix-content">
            <div class="prod-item" v-for="prod in prodList" :key="prod.name">
              <div class="text">
                <img class="text-icon" :src="prod.icon" width="40">
                <div>
                  <p class="name">{{prod.name}}</p>
                  <p>{{prod.desc}}</p>
                </div>
              </div>
              <div class="img-box">
                <img :src="prod.img" :width="prod.width">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="use">
        <div class="use-wrap">
          <p class="title">应用场景</p>
          <div class="use-content">
            <swiper class="swiper swiper-no-swiping">
              <swiper-slide class="slide" v-for="item in swiperList" :key="item.img">
                <div class="slide-main">
                  <div v-html="item.textHtml" class="text"></div>
                  <img :src="item.img" :width="item.width" :style="item.style"/>
                </div>
              </swiper-slide>
              <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script lang="js">
import { swiper, swiperSlide, Swiper } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import lottie from 'lottie-web'

export default {
  components: {
    swiper,
    swiperSlide
  },
  data () {
    return {
      prodList: [
        {
          name: '深眸',
          desc: '中国企业专利评价与科技力预测系统',
          icon: 'https://images.zzt.com.cn/ox/2022/12/23/pate-icon.png',
          img: 'https://images.zzt.com.cn/ox/2022/12/23/prod-pate.png',
          width: 400
        },
        {
          name: '小蓝书',
          desc: '中国企业专利价值评估与科技力评测报告',
          icon: 'https://images.zzt.com.cn/ox/2022/12/23/bluebook-icon.png',
          img: 'https://images.zzt.com.cn/ox/2022/12/23/prod-bluebook.png',
          width: 238
        },
        {
          name: '深蓝',
          desc: '中国高价值科技企业挖掘识别系统',
          icon: 'https://images.zzt.com.cn/ox/2022/12/23/blue-icon.png',
          img: 'https://images.zzt.com.cn/ox/2022/12/23/prod-blue.png',
          width: 398
        },
        {
          name: '数据接口平台',
          desc: '知识产权大数据开放数据接口平台',
          icon: 'https://images.zzt.com.cn/ox/2022/12/23/openAPI-icon.png',
          img: 'https://images.zzt.com.cn/ox/2022/12/23/prod-openAPI.png',
          width: 500
        }
      ],
      swiperList: [
        { textHtml: '为关注企业长期价值的投资者<br/>提供关键性的数据支撑', img: 'https://images.zzt.com.cn/ox/2022/12/23/use-1.png', width: '908', style: 'margin-left: -140px;' },
        { textHtml: '精准刻画企业科技力、可持续科技发展潜力<br/>及未来市场价值，从而转化为信贷动能', img: 'https://images.zzt.com.cn/ox/2022/12/23/use-2.png', width: '708' },
        { textHtml: '数字化估值，精准推送，内容运营工具<br/>', img: 'https://images.zzt.com.cn/ox/2022/12/23/use-3.png', width: '646' }
      ]
    }
  },
  mounted () {
    this.lottie = lottie.loadAnimation({
      container: document.getElementById('banner-img'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: 'https://images.zzt.com.cn/ox/2022/12/26/data.json'
    })
    this.swiper = new Swiper('.swiper', {
      autoplay: {
        autoplay: true,
        disableOnInteraction: false,
        delay: 5000
      },
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        renderBullet: (index, className) => {
          let text = ''
          switch (index) {
            case 0:text = '资本市场科创服务'; break
            case 1:text = '知识产权金融服务'; break
            case 2:text = '知识产权数字化流通'; break
          }
          return '<div class="' + className + '">' + text + '</div>'
        }
      }
    })
    this.swiper.el.onmouseover = function () {
      this.swiper.autoplay.stop()
    }
    this.swiper.el.onmouseleave = function () {
      this.swiper.autoplay.start()
    }
  }
}
</script>

<style lang='scss' scoped>
.prod {
  p {
    margin: 0;
  }
  .banner-wrap {
    background-color: #E0EDFB;
  }
  .banner {
    width: 100%;
    min-width: $container-width;
    max-width: Translate(1920px);
    height: Translate(500px);
    background: url(https://images.zzt.com.cn/ox/2022/12/23/prod-banner-bg.png) no-repeat;
    background-size: cover;
    background-position: center center;
    margin: 0 auto;

    &-box {
      width: $container-width;
      margin: 0 auto;
      position: relative;
      height: 100%;
    }
    &-text {
      text-align: left;
      position: absolute;
      top: Translate(167px);
      left: 0;
      font-size: Translate(40px);
      font-weight: 500;
      .title {
        line-height: Translate(64px);
      }
    }
    &-img {
      width: Translate(294px);
      position: absolute;
      right: Translate(50px);
      top: Translate(46px);
    }
  }
  section {
    background-color: #fff;
    img {
      font-size: 0;
    }
    .matrix, .use {
      padding-top: Translate(74px);
      &-wrap {
        width: $container-width;
        margin: 0 auto;
      }
      .title {
        font-size: Translate(36px);
        font-weight: 500;
      }
    }
    .matrix {
      &-content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: Translate(52px);
      }
      .prod-item {
        margin-bottom: Translate(66px);
        .text {
          display: flex;
          align-items: center;
          text-align: left;
          margin-bottom: Translate(24px);
          &-icon {
            margin-right: Translate(14px);
          }
          .name {
            font-size: Translate(20px);
            font-weight: 500;
            margin-bottom: Translate(10px);
          }
        }
        .img-box {
          width: Translate(580px);
          height: Translate(330px);
          background: #F5F6F9;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .use {
      height: Translate(790px);
      box-sizing: border-box;
      background: url(https://images.zzt.com.cn/ox/2022/12/23/use-bg.png) no-repeat;
      background-size: cover;
      background-position: center center;
      max-width: Translate(1920px);
      min-width: $container-width;
      margin: 0 auto;
      .title {
        margin-bottom: Translate(40px);
      }
      .slide {
        &-main {
          width: $container-width;
          height: Translate(450px);
          display: flex;
          margin-top: Translate(96px);
          align-items: center;
          justify-content: space-around;
          background-color: #fff;
          .text {
            font-size: Translate(18px);
            line-height: Translate(34px);
            font-weight: 500;
          }
        }
      }
    }

  }
}
</style>
<style lang="scss">
.swiper-pagination {
  position: absolute;
  top: 0;
  height: Translate(60px);
  line-height: Translate(60px);
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: Translate(1px) solid#CCD6E4;
}
.swiper-pagination-bullet {
  width: Translate(200px) !important;
  margin: 0 Translate(80px) !important;
  opacity: 1;
  background: none;
  height: 100%;
  font-size: Translate(20px);
  font-weight: 500;
  &-active {
    color: #4088EB;
    border-bottom: Translate(2px) solid #4088E8;
    border-radius: 0;
  }
}
</style>
